import Home from './home/Home';
import { BrowserRouter as Router } from 'react-router-dom'
import Context from './utils/Context';
import { useState,  Suspense } from 'react';
import { Spin } from 'antd';


function App() {

  //Produção Nuvem
  //const CurlPadrao = "https://www.api.thbytes.com.br:8443/musa-hot-back/"

  //Dev Localhost
  const CurlPadrao = "http://localhost:8080/"


  const [CcidadeId, CsetCidadeId] = useState()
  const [CcidadeNome, CsetCidadeNome] = useState()
  const [CacompanhanteNome, CsetAcompanhanteNome] = useState()
  const [CacompanhanteId, CsetAcompanhanteId] = useState([])

  return (

    <div>

      <Context.Provider value={{
        CurlPadrao,
        CcidadeId, CsetCidadeId, CcidadeNome, CsetCidadeNome,
        CacompanhanteNome, CsetAcompanhanteNome, CacompanhanteId, CsetAcompanhanteId
      }}>

        <Router>

          <Suspense fallback={<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spin></Spin></div>}>
            <Home></Home>
          </Suspense>

        </Router>
      </Context.Provider>

    </div>
  );
}

export default App;
