//import Aviso18 from '../pages/aviso18/Aviso18';
//import Conteudo from './conteudo/Conteudo';
//import Topo from './topo/Topo';
import React, { lazy } from 'react';

const Topo = lazy(() => import('./topo/Topo'))
const Conteudo = lazy(() => import('./conteudo/Conteudo'))

function Home() {
    return (

        <div>

            {/*<Aviso18></Aviso18>*/}
            <Topo></Topo>
            {/*<Local></Local>*/}
            <Conteudo></Conteudo>

        </div>
    );
}

export default Home;
